import React from "react";
import "./menubar.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const img = {
  verticalAlign: "-15%",
};

const Menubar = (props) => {
  return (
    <>
      <Navbar
        expand="lg"
        bg="dark"
        variant="dark"
        // sticky="top"
        className="fixed-top"
      >
        {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
        {/* <img
          src="/garden-go-logo-ipad-resized-64x-64x.png"
          width="24"
          height="24"
          className="d-inline-block align-middle align-right"
          // style={img}
        /> */}
        <Container>
          <Navbar.Brand href="/">
            <img
              src="/garden-go-logo-ipad-resized-64x-64x.png"
              width="24"
              height="24"
              className="d-inline-block"
              style={img}
            />{" "}
            GardenGo
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/dashboard">Dashboard</Nav.Link>
              <Nav.Link href="/insights">Insights</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Menubar;
