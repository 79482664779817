import React from "react";

const subHeaderStyle = {
  color: "black",
  fontStyle: "italic",
};

const bannerStyle = {
  background: "rgba(64, 224, 208, 0.25)",
  height: "850px",
};

const MainBanner = (props) => {
  return (
    <div className="text-center align-middle " style={bannerStyle}>
      <div className="header container text-center align-middle">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <img src="/garden-go-logo-ipad-resized.png" width="384" height="384" />
        <h1>Garden Go</h1>
        <h2 style={subHeaderStyle}>
          A Raspberry Pi weather station and plant watcher.
        </h2>
      </div>
    </div>
  );
};

export default MainBanner;
