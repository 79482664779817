import React from "react";

import Menubar from "../components/menubar";
import Footer from "../components/footer/footer";

const bannerStyle = {
  background: "rgba(64, 224, 208, 0.25)",
  height: "850px",
};

const ComingSoonBanner = (props) => {
  return (
    <div className="text-center align-middle " style={bannerStyle}>
      <div className="header container text-center align-middle">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <img src="/garden-go-logo-ipad-resized.png" width="384" height="384" />
        <h1>Page Coming Soon</h1>
      </div>
    </div>
  );
};

class ComingSoon extends React.Component {
  render() {
    return (
      <div>
        <Menubar />
        <ComingSoonBanner />
        <Footer />
      </div>
    );
  }
}

export default ComingSoon;
