import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Grid } from "react-loader-spinner";

const loadingStyle = {
  height: "500px",
};

const MainLoadingGrid = (props) => {
  return (
    <Row className="justify-content-md-center" style={loadingStyle}>
      <Col md="auto">
        <Grid
          height={props.size}
          width={props.size}
          color="#40e0d040"
          ariaLabel="grid-loading"
          radius="12.5"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </Col>
    </Row>
  );
};

export default MainLoadingGrid;
