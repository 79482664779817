import React from "react";
import axios from "axios";
import MainLoadingGrid from "../components/main-loading-grid";
import Container from "react-bootstrap/esm/Container";

var QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");
var dashboard;

function embedDashboard(url) {
  if (url === "") return;

  var containerDiv = document.getElementById("embeddingContainer");
  var options = {
    url: url,
    container: containerDiv,
    parameters: {
      country: "United States",
    },
    scrolling: "no",
    height: "700px", // TODO: Scale with page using React state & page events
    // width: "1000px",
    width: parentWidth(document.getElementById("embeddingContainer")),
    locale: "en-US",
    footerPaddingEnabled: true,
  };
  dashboard = QuickSightEmbedding.embedDashboard(options);
}

function parentWidth(elem) {
  return elem.parentElement.clientWidth;
}

class QuickSightDashboard extends React.Component {
  constructor() {
    super();

    const username = JSON.parse(localStorage.getItem("jwtDecoded")).username;

    this.state = {
      quicksightUrl: "",
      quicksightIsLoaded: false,
      username: username,
    };
  }

  async componentDidMount() {
    const url = `https://api.gardengo.xyz/quicksight/users/${this.state.username}`;

    // TODO: Check if url is expired
    let quicksightUrl = this.state.quicksightUrl;
    if (quicksightUrl === "") {
      const response = await axios.post(url, {
        headers: {
          Authorization: localStorage.getItem("jwtEncoded"),
          "Content-Type": "application/json",
        },
      });

      quicksightUrl = response.data.url;

      this.setState({
        quicksightUrl,
        quicksightIsLoaded: true,
      });
    }

    embedDashboard(quicksightUrl);
  }

  render() {
    return (
      <div>
        <br />
        <br />

        {!this.state.quicksightIsLoaded ? (
          <MainLoadingGrid size="400" />
        ) : (
          <Container>
            <h1>GardenGo Sensor Insights</h1>
            <p>Note: Sensor readings are updated weekly.</p>
          </Container>
        )}
        <Container id="quicksight-container">
          <div id="embeddingContainer"></div>
        </Container>
      </div>
    );
  }
}

export default QuickSightDashboard;
