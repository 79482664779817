import React from "react";
import Menubar from "../components/menubar";
import QuickSightDashboard from "../components/quicksight";
import Footer from "../components/footer/footer";

class Insights extends React.Component {
  render() {
    return (
      <div>
        <br />
        <br />
        <Menubar />
        <QuickSightDashboard />
        <br />
        <br />
        <Footer />
      </div>
    );
  }
}

export default Insights;
