import React from "react";

import Menubar from "../components/menubar";
import Footer from "../components/footer/footer";
import MainBanner from "../components/main-banner";
import Carousel from "react-bootstrap/Carousel";

const plantWatcherImages = [
  {
    src: "/plant-watcher-v1-0-wide.jpg?text=First slide&bg=373940",
    label: "",
    description: "",
  },
  {
    src: "/plant-watcher-v1-0-close.jpg?text=First slide&bg=373940",
    label: "",
    description: "",
  },
];

const weatherStationZeroImages = [
  {
    src: "/ws-zero-v0-1-outdoors.jpg?text=First slide&bg=373940",
    label: "",
    description: "",
  },
  {
    src: "/ws-zero-v0-1-circuit-close.jpg?text=First slide&bg=373940",
    label: "",
    description: "",
  },
  {
    src: "/ws-zero-v0-1-inside-close.jpg?text=First slide&bg=373940",
    label: "",
    description: "",
  },
  {
    src: "/ws-zero-v0-1-underside.jpg?text=First slide&bg=373940",
    label: "",
    description: "",
  },
];

const weatherStationOneImages = [
  {
    src: "/ws-one-v0-1-circuit-close.jpg",
    label: "",
    description: "",
  },
  {
    src: "/ws-one-v0-1-exterior-close.jpg",
    label: "",
    description: "",
  },
  {
    src: "/ws-one-v0-1-outdoors-sunny.jpg",
    label: "",
    description: "",
  },
  {
    src: "/ws-one-v0-1-outdoors-cloudy.jpg",
    label: "",
    description: "",
  },
];

function buildCarouselItem(image) {
  return (
    <Carousel.Item>
      <img className="d-block w-100" src={image.src} alt="First slide" />
      <Carousel.Caption>
        <h3>{image.label}</h3>
        <p>{image.description}</p>
      </Carousel.Caption>
    </Carousel.Item>
  );
}

function imageCarousel(images) {
  return (
    <Carousel interval={null}>
      {images.map((image) => buildCarouselItem(image))}
    </Carousel>
  );
}

const contentStyle = {
  backgroundColor: "#fafafa",
};

class Home extends React.Component {
  render() {
    return (
      <div>
        <Menubar />
        <MainBanner />

        <div style={contentStyle}>
          <br />
          <br />
          <div className="container">
            <h2>GardenGo</h2>
            <p>
              GardenGo is a custom-built weather station and plant watcher. It
              primarily includes:
              <ol>
                <li>A Terraform, Go & Python backend deployed on AWS.</li>
                <li>
                  A Go & Python app deployed on a number of Raspberry Pis in
                  order to gather sensor data.
                </li>
                <li>
                  A React & Bootstrap frontend (note: I'm not a frontend
                  developer).
                </li>
              </ol>
            </p>
          </div>
          <br />
          <br />
          <div className="container">
            <h3>Weather Station - Passive (ws0)</h3>
            <p>
              GardenGo has a number of passive sensors deployed that monitor the
              current weather conditions. These sensors currently monitor the
              surrounding temperature and humidity.
            </p>
            <p>
              This station includes a 120mm fan that brings airflow over the
              sensors, ensuring that the sensor's reading are always
              representative of their environment.
            </p>
            {imageCarousel(weatherStationZeroImages)}
          </div>
          <br />
          <br />
          <div className="container">
            <h3>Weather Station - Active (ws1)</h3>
            <p>
              Active sensors are helpful when making certain measurements. These
              sensors provide insight regarding: wind speeds, wind gusts, wind
              direction and rainfall. Please note that this station is currently
              deployed on a appartment balcony, so the reading provided will not
              always be accurate, especially regarding wind direction.
            </p>
            {imageCarousel(weatherStationOneImages)}
          </div>
          <br />
          <br />
          <div className="container">
            <h3>Plant Watcher</h3>
            <p>
              GardenGo also supports plant watching and watering. Testing is
              currently being done in order to determine a reliable way to get
              soil moisture content.
            </p>
            {imageCarousel(plantWatcherImages)}
          </div>
          <br />
          <br />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
