// import React from "react";
import "./footer.css";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";

const websiteUrls = [
  {
    text: "Website base - HTML & CSS",
    url: "https://www.tinkercad.com/",
  },
  {
    text: "Website base - React",
    url: "https://www.w3schools.com/howto/howto_make_a_website.asp",
  },
];

const sensorUrls = [
  {
    text: "Adafruit",
    url: "https://www.adafruit.com/",
  },
  {
    text: "Stemma QT",
    url: "https://learn.adafruit.com/introducing-adafruit-stemma-qt",
  },
  {
    text: "Pi Weather Station<",
    url: "https://projects.raspberrypi.org/en/projects/build-your-own-weather-station",
  },
];

const stencilUrls = [
  {
    text: "3D printing resources",
    url: "https://all3dp.com/",
  },
  {
    text: "SVD sesigner",
    url: "https://inkscape.org/",
  },
  {
    text: "SVD to STL converter",
    url: "https://www.tinkercad.com/",
  },
  {
    text: "Slicer software",
    url: "",
  },
];

const footerStyle = {
  background: "rgba(247, 209, 160, 0.25)",
};

const codebaseComingSoonMsg =
  "Codebases are currently under maintenance, please check back later.";

const links = {
  codebase: [
    {
      desc: "GardenGo group",
      url: "https://gitlab.com/garden-go",
    },
    {
      desc: "Back end & API",
      url: "https://gitlab.com/garden-go/garden-go-api",
    },
    {
      desc: "Persistent data",
      url: "https://gitlab.com/garden-go/garden-go-persistent-data",
    },
    {
      desc: "Terraform state",
      url: "https://gitlab.com/garden-go/garden-go-tf-state",
    },
    {
      desc: "Sensors (RPi)",
      url: "https://gitlab.com/garden-go/garden-go-rpi",
    },
    {
      desc: "Web infrastructure",
      url: "https://gitlab.com/garden-go/garden-go-web-infra",
    },
    {
      desc: "Web app",
      url: "https://gitlab.com/garden-go/garden-go-web-app",
    },
  ],
  keyResources: [
    {
      desc: "Adafruit",
      url: "https://www.adafruit.com",
    },
    {
      desc: "RPi Weather Station",
      url: "https://projects.raspberrypi.org/en/projects/build-your-own-weather-station",
    },
    {
      desc: "Bootstrap React",
      url: "https://react-bootstrap.github.io",
    },
    {
      desc: "Tinkercad",
      url: "https://www.tinkercad.com",
    },
  ],
};

class FooterLink extends React.Component {
  constructor(props) {
    super();
    this.state = {
      linkInfo: props.linkInfo,
      show: false,
      setShow: false,
    };
  }

  buildModalBodyText() {
    if (!!this.state.linkInfo.message) {
      return this.state.linkInfo.message;
    } else {
      return `${this.state.linkInfo.desc} link has not been added yet, please check back later.`;
    }
  }

  render() {
    const handleClose = () => {
      this.setState({ show: false });
    };
    const handleShow = () => {
      this.setState({ show: true });
    };

    return (
      <>
        <a onClick={handleShow} class="nav-link p-0 text-muted">
          {this.state.linkInfo.desc}
        </a>
        <Modal show={this.state.show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.linkInfo.desc}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.buildModalBodyText()}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

class FooterLinks extends React.Component {
  buildFooterLink(linkInfo) {
    return (
      <li class="nav-item mb-2">
        {linkInfo.url ? (
          <a href={linkInfo.url} class="nav-link p-0 text-muted">
            {linkInfo.desc}
          </a>
        ) : (
          <FooterLink linkInfo={linkInfo} />
        )}
      </li>
    );
  }

  render() {
    return (
      <>
        <div class="col mb-3">
          <h5>Codebase</h5>
          <ul class="nav flex-column">
            {links.codebase.map((link) => this.buildFooterLink(link))}
          </ul>
        </div>
        <div class="col mb-3">
          <h5>Key Resources</h5>
          <ul class="nav flex-column">
            {links.keyResources.map((link) => this.buildFooterLink(link))}
          </ul>
        </div>
      </>
    );
  }
}

const Footer = (props) => {
  return (
    <div style={footerStyle}>
      <div class="container">
        <footer class="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 border-top">
          <div class="col mb-3">
            <a
              href="/"
              class="d-flex align-items-center mb-3 link-dark text-decoration-none"
            >
              <img
                src="/garden-go-logo-ipad-resized-64x-64x.png"
                width="40"
                height="40"
              />
            </a>
            <p class="text-muted">&copy; 2022</p>
          </div>

          <div class="col mb-3"></div>

          <FooterLinks />
        </footer>
      </div>
      <div class="container" style={subFooterStyle}>
        <footer class="row row-cols-1 py-5 border-top text-center">
          <p class="nav-link p-0 text-muted">GardenGo is licensed under MIT.</p>
        </footer>
      </div>
    </div>
  );
};

const subFooterStyle = {
  display: "flex !important;",
  justifyContent: "space-between",
};

export default Footer;
